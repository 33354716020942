@import "app/styles/variables";
@import "app/styles/mixins";

.field {
  display: flex;
  align-items: center;

  .fieldButton {
    @include text-small;
  }

  .filterSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 7px;
    min-height: 7px;
    margin-left: 5px;
    background: $primary-color;
    border-radius: 100%;
  }
}
