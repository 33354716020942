@import "app/styles/mixins";

.menu {
  padding: 12px 24px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    @include text-large;
    word-break: break-all;
    text-align: center;
    margin-top: 20px;
  }

  .logo {
    cursor: pointer;
  }
}
