// корпоративные цвета
$primary-color: #00c337;
$secondary-color: #666666;
$red-color: #f00000;
$black-color: #000000;
$white-color: #ffffff;
$yellow-color: #f6f6df;
$divider-color: #e6e6e6;
$lightgreen-color: #90ee90;

// функциональные переменные
$error-color: $red-color;

// border-color
$border-primary: 1px solid $primary-color;
$border-dark: 1px solid $secondary-color;
$border-error: 1px solid $error-color;
$border-table: 1px solid $divider-color;

// border-radius
$border-radius: 4px;
$border-radius-big: 26px;

// background
$primary-background-hover: #00ad31;
$primary-background-focus: #00992b;
$secondary-background-hover: rgba(0, 195, 55, 0.08);
$secondary-background-focus: rgba(0, 195, 55, 0.16);
$lightgreen-background-hover: #9ee09e;
$lightgreen-background-focus: #7db87d;

// font family
$font-family: "Manrope";

// font-size
$font-size-h1: 40px;
$font-size-large: 16px;
$font-size-medium: 14px;
$font-size-small: 12px;

// line-height
$line-height-h1: 55px;
$line-height-large: 24px;
$line-height-medium: 20px;
$line-height-small: 16px;

// font-weight
$font-weight-h1: 700;
$font-weight-large: 500;
$font-weight-medium: 500;
$font-weight-small: 500;

// box-shadow
$box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
