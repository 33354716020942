@import "app/styles/variables";
@import "app/styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .container {
    display: inline-block;
    position: relative;

    .input {
      width: 100%;
      height: 44px;
      padding: 12px 36px 12px 36px;
      box-sizing: border-box;
      @include text-medium;
      border: $border-dark;
      border-radius: $border-radius;

      &::placeholder {
        $color: $secondary-color;
      }

      &:focus {
        outline: none;
        border: $border-primary;
        background: $secondary-background-focus;
      }

      &:hover {
        background: $secondary-background-hover;
      }
    }

    .inputError {
      border: $border-error;

      &:focus {
        border: $border-error;
      }
    }

    .accountIcon,
    .clearIcon {
      position: absolute;
    }

    .accountIcon {
      bottom: 13px;
      left: 12px;
    }

    .clearIcon {
      bottom: 16px;
      right: 12px;
      cursor: pointer;
    }
  }

  .errorText {
    margin-top: 4px;
    @include text-small($color: $error-color);
  }
}