@import "app/styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  h1 {
    display: inline-block;
    @include text-h1;
    clip-path: inset(0 1.5ch 0 0);
    animation: l 1s steps(6) infinite;
  }
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
