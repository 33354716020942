@import "app/styles/variables";
@import "app/styles/mixins";

.dropdown {
  position: relative;

  .field {
    display: flex;
    align-items: center;
    @include text-large;
    cursor: pointer;

    .arrow {
      margin-left: 10px;
      transition: .3s;
      transform: rotate(0deg);
    }
  }

  .listContainer {
    position: absolute;
    max-height: 300px;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border: $border-dark;
    background-color: $white-color;
    margin-top: 10px;
    z-index: 1000;

    .listItem {
      display: flex;
      gap: 10px;
      padding: 10px;
      white-space: nowrap;
      @include text-large;
      cursor: pointer;

      &:hover {
        color: $secondary-color;
      }

      &.longText {
        white-space: normal;
      }
    }
    
  }
}

.isOpen {
  .field {
    .arrow {
      transform: rotate(180deg);
    }
  }
}