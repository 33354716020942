@import "app/styles/mixins";

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  padding: 12px 24px;

  .cardsList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    gap: 20px;
    overflow-y: auto;

    .link {
      text-decoration: none;

      .card {
        display: flex;
        flex-direction: column;
        height: 151px;
        width: 209px;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .date {
      @include text-large;
      text-align: center;
      margin: 10px 0px;
    }
  }
}

@include for-big-desktop {
  .page {
    .cardsList {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

@include for-desktop {
  .page {
    .cardsList {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

@include for-tablet-landscape {
  .page {
    .cardsList {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@include for-tablet-portrait {
  .page {
    .cardsList {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include for-phone-only {
  .page {
    .cardsList {
      grid-template-columns: repeat(1, 1fr);

      .link {
        margin: auto;
      }
    }
  }
}
