@import "app/styles/variables";
@import "app/styles/mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    @include text-large;
  }
}

.selectAllButton {
  margin: 20px 0px;
  @include text-large;
  text-decoration: underline;
}

.listContainer {
  max-height: 300px;
  min-width: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  .label {
    display: flex;
    align-items: center;
    
    input {
      cursor: pointer;
    }

    .text {
      display: flex;
      padding: 10px;
      @include text-large;
      text-align: left;
      cursor: pointer;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}
