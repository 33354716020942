@import "app/styles/mixins";

.wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  span {
    @include text-h1;
    flex: 1;
    // margin-left: 14%;
  }

  .status {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .arrowIcon {
    max-height: 30px;
    width: 18%;
    display: flex;
  }
}
