@import "app/styles/variables";
@import "app/styles/mixins";

.link {
  max-width: 100%;
  height: 44px;
  background-color: $lightgreen-color;
  border-radius: $border-radius-big;
  border: 0;
  padding: 12px 16px;
  box-sizing: border-box;
  transition: 0.3s;
  @include text-medium;
  cursor: pointer;
  text-decoration: none;
  box-shadow: $box-shadow;

  &:hover {
    background-color: $lightgreen-background-hover;
  }

  &:focus {
    background-color: $lightgreen-background-focus;
  }

  &:disabled {
    opacity: 0.3;
  }
}
