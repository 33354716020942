@import "app/styles/variables";
@import "app/styles/mixins";

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
}

.container {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  display: block;
  width: auto;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
  background: $white-color;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    @include text-large;
  }
}
