@import "app/styles/variables";
@import "app/styles/mixins";

.tableWrapper {
  overflow-y: auto;
  border: $border-table;
  border-radius: 12px;
}

.table {
  position: relative;
  width: 100%;
  border-collapse: collapse;

  .headerCell {
    position: sticky;
    top: 0;
    left: 0;
    padding: 12px 24px;
    background-color: $yellow-color;
    @include text-small;
    text-align: left;
  }

  .row {
    &:not(:last-child) {
      border-bottom: $border-table;
    }
  }

  .bodyCell {
    padding: 16px 24px;
    @include text-medium;
    text-align: left;
  }
}

.noDataText {
  margin: 20px 0px;
  text-align: center;
  @include text-small($color: $secondary-color);
}
