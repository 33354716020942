@import "app/styles/mixins";

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;

  .logoFlex {
    display: flex;
    align-items: center;
  }

  .flowFlex {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0px 20px;
    @include text-large;

    .arrowIcon {
      margin: 0px 12px;
    }
  }

  .buttonFlex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .logo {
    cursor: pointer;
  }
}