@import "app/styles/variables";
@import "app/styles/mixins";

.wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 195, 55, 0.1), rgba(0, 195, 55, 0.1)), #FFFFFF;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: $white-color;
    border-radius: 17px;
  }

  .emailContainer {
    width: 240px;
    margin-top: 20px;
  }

  .passwordContainer {
    width: 240px;
    margin-top: 12px;
  }

  .buttonContainer {
    width: 100%;
    margin-top: 20px;

    .button {
      width: 100%;
    }
  }
}