@import "app/styles/variables";
@import "app/styles/mixins";

.errorContainer {
  margin: 10px 0px;
  text-align: center;

  .errorText {
    @include text-small($color: $error-color);
  }
}