@import "app/styles/variables";
@import "app/styles/mixins";

.button {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 20px;
  background: none;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  transition: 0.3s;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }
}
