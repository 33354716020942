@import "app/styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  text-align: center;
  padding: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  span {
    @include text-large;
  }
}
