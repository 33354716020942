@font-face {
  font-family: "Manrope";
  src: url("../../../app/fonts/Manrope/static/Manrope-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../app/fonts/Manrope/static/Manrope-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../app/fonts/Manrope/static/Manrope-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../app/fonts/Manrope/static/Manrope-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../app/fonts/Manrope/static/Manrope-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../app/fonts/Manrope/static/Manrope-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../app/fonts/Manrope/static/Manrope-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}