@import "app/styles/mixins";

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  padding: 12px 24px;

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .date {
      @include text-large;
      text-align: center;
      margin: 10px 0px;
    }

    .buttons {
      display: flex;

      .backButton {
        margin-right: 12px;
      }
    }
  }
}
