@import "variables";

@mixin text-h1($size: $font-size-h1,
  $line-height: $line-height-h1,
  $weight: $font-weight-h1,
  $color: $black-color) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  color: $color;
}

@mixin text-large($size: $font-size-large,
  $line-height: $line-height-large,
  $weight: $font-weight-large,
  $color: $black-color) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  color: $color;
}

@mixin text-medium($size: $font-size-medium,
  $line-height: $line-height-medium,
  $weight: $font-weight-medium,
  $color: $black-color) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  color: $color;
}

@mixin text-small($size: $font-size-small,
  $line-height: $line-height-small,
  $weight: $font-weight-small,
  $color: $black-color) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  color: $color;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait {
  @media (min-width: 600px) and (max-width: 899px) {
    @content;
  }
}

@mixin for-tablet-landscape {
  @media (min-width: 900px) and (max-width: 1199px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 1200px) and (max-width: 1799px) {
    @content;
  }
}

@mixin for-big-desktop {
  @media (min-width: 1800px) {
    @content;
  }
}