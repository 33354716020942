@import "variables";

html,
body,
#root,
.app {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
}

h1,
p {
  margin: 0;
  padding: 0;
}