@import "app/styles/variables";
@import "app/styles/mixins";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: $primary-color;
  border-radius: 30px;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: $box-shadow;

  &:hover {
    background-color: $primary-background-hover;
  }

  &:focus {
    background-color: $primary-background-focus;
  }

  &:disabled {
    opacity: 0.3;
  }
}

.secondary {
  background: $white-color;
  border: $border-primary;

  &:hover {
    background-color: $secondary-background-hover;
  }

  &:focus {
    background-color: $secondary-background-focus;
  }
}
